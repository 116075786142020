import '../../assets/css/global.css'
export default function Videos(props: any) {

    return (
        <>
            <div className={`${props.colSize}`} onContextMenu={(e) => e.preventDefault()}>
                <div className="card mb-4">
                    <div className="card-body" id={`${props.id}`}>
                        <h5 className="card-title text-center">{props.title}</h5><hr />
                        <h6 className="card-subtitle mb-3 text-body-secondary text-center">{props.subTitle}</h6>
                        <video key={props.key}
                            className="video object-fit-sm-contain border rounded"
                            autoPlay
                            loop
                            muted
                            controls={false}
                            title={props.title}>
                            <source src={props.video} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </>
    )
}