import Menu from "../global/Menu"
import '../../assets/css/global.css'
import locale from '../../i18n/pt-BR.json'
import ModalService from "../global/ModalService"
import Videos from "../global/Videos"
import 'bootstrap-icons/font/bootstrap-icons.css';

export default function Home(props: any) {

  return (

    <>
      <Menu />
      <ModalService id={localStorage.getItem('href')} />
      <div className="container-fluid margem">
        <h1 className="text-center">{locale.menu.home}</h1>
        <div className="row overflow">

          {
            locale.videos.furacao?.map((file, index) => (
              <>
                <Videos
                  colSize='col-sm-12 col-md-4'
                  video={locale.videos.url.concat(file)}
                  title={locale.menu.service.subItem.furacao}
                  subTitle={locale.videos.description.furacao} key={`${file.concat(`-${index}`)}`}
                />
              </>
            ))
          }
          {
            locale.videos.montagem?.map((file, index) => (
              <>
                <Videos
                  colSize='col-sm-12 col-md-4'
                  video={locale.videos.url.concat(file)}
                  title={locale.menu.service.subItem.montagem[index]}
                  subTitle={locale.videos.description.montagem[index]} key={`${file.concat(`-${index}`)}`}
                />
              </>
            ))
          }
          {
            locale.videos.arremates?.map((file, index) => (
              <>
                <Videos
                  colSize='col-sm-12 col-md-4'
                  video={locale.videos.url.concat(file)}
                  title={locale.menu.service.subItem.arremate[index]}
                  subTitle={locale.videos.description.arremate[index]} key={`${file.concat(`-${index}`)}`}
                />
              </>
            ))
          }
          {
            locale.videos.paletizacao?.map((file, index) => (
              <>
                <Videos
                  colSize='col-sm-12 col-md-4'
                  video={locale.videos.url.concat(file)}
                  title={locale.menu.service.subItem.paletizacao}
                  subTitle={locale.videos.description.paletizacao} key={`${file.concat(`-${index}`)}`}
                />
              </>
            ))
          }
          
        </div>
      </div>
    </>
  )
}